import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FileServer } from 'src/app/services/fileserver.service';

@Component({
  selector: 'logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  @Input() logo_path: string = null;
  @Input() type: string = 'standard_grid';
  @Input() name: string = null;

  public width: number = null;
  public height: number = null;
  public src: string = null;
  public bg_color: string = 'transparent';

  constructor(private fileserver: FileServer) {}

  ngOnInit(): void {
    this.setSize();
    this.setLogoSource();
    this.setBackgroundColorAndName();
  }
  
  setSize() {
    switch (this.type) {
      case 'standard_grid':
      case 'grid':
        this.width = 50;
        this.height = 50;
        break;
    }
  }

  setLogoSource() {
    if (this.type && this.type !== 'standard_grid') {
      this.src = this.logo_path
        ? this.fileserver.getMediaFile(this.logo_path)
        : null;
    } else {
      this.src = this.logo_path || null;
    }
  }

  setBackgroundColorAndName() {
    if (this.name) {
      this.bg_color = this.stringToColorCode(this.name);
      const nameParts = this.name.split(' ');
      if (nameParts.length > 1) {
        this.name = nameParts[0].charAt(0) + nameParts[1].charAt(0);
      } else {
        this.name = this.name.substring(0, 2);
      }
    }
  }

  stringToColorCode(str) {
    // Ensure the string is exactly 3 characters long
    str = str.substr(0, 3);
    while (str.length < 3) {
      str += '0'; // Append '0' if less than 3 characters
    }

    // Convert each character to ASCII, then map to a lighter, pastel-like RGB range
    const rgb = str.split('').map((char) => {
      // Scale ASCII to 0-127, then adjust to the pastel range (191-255)
      return Math.floor((char.charCodeAt(0) / 127.5) * 64 + 191);
    });

    // Convert each number in the RGB array to a two-digit hexadecimal code
    const hex = rgb.map((num) => {
      const hexValue = num.toString(16);
      return hexValue.length === 1 ? '0' + hexValue : hexValue; // Ensure two digits
    });

    // Combine the hex values into a CSS color code
    return `#${hex.join('')}`;
  }
}
